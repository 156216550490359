



















































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import config from '@/utils/config'
const initFormData = () => {
  return {
    id: '',
    parentId: '',
    name: '',
    lat: null,
    lon: null,
  }
}
export default Vue.extend({
  data() {
    return {
      baseData: [{}],
      currentId: undefined,
      formData: initFormData(),
      rules: {
        id: [{ required: true, message: '区域编码不能为空', trigger: 'blur' }],
        parentId: [{ required: true, message: '父区域编码不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '区域名称不能为空', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      request.get('/api/sys/area/all_list', null)
      .then((data) => {
        this.baseData = this.buildTreeNodes(data)
      })
    },
    buildTreeNodes(data: any): any[] {
      if (!data || data.size === 0) {
        return []
      }
      const array: any[] = []
      const nodeMap: any = {}
      data.forEach((e: any) => {
        const { id, parentId } = e
        const node = this.buildNode(e)
        nodeMap[id] = node
        if (nodeMap[parentId]) {
          nodeMap[parentId].children.push(node)
        } else {
          array.push(node)
        }
      })
      return array
    },
    buildNode(geoData: any) {
      const { id, parentId, name, lat, lon } = geoData
      const node = {id, title: `${id} ${name}`, name, lat, lon, parentId}
      if (id.length !== 6) {
        return node
      }
      if (id === config.defaultStationObj.adminCode) {
        const nodeNew = {...node, expand: true, children: []}
        return nodeNew
      }
      return node
    },
    onSelect(selectedNodeList: any[]) {
      const { id, name, lat, lon, parentId } = selectedNodeList[0]
      this.currentId = id
      this.formData = { id, name, lat, lon, parentId }
    },
    insert() {
      const params = this.formData
      const dataForm: any = this.$refs.dataForm
      dataForm.validate((valid: boolean) => {
        if (valid) {
          request.post('/api/sys/area', params)
          .then((res) => {
            this.$Message.success('新增成功!')
            this.formData = initFormData()
            this.getData()
          }).catch((errorMsg: string) => this.$Message.error(errorMsg))
        }
      })
    },
    update() {
      const params = this.formData
      const dataForm: any = this.$refs.dataForm
      dataForm.validate((valid: boolean) => {
        if (valid) {
          request.put(`/api/sys/area/${this.currentId}`, params)
          .then((res) => {
            this.$Message.success('修改成功!')
            this.formData = initFormData()
            this.getData()
          }).catch((errorMsg: string) => this.$Message.error(errorMsg))
        }
      })
    },
    deleteItem(id: string) {
      request.del(`/api/sys/area/${id}`)
      .then(() => {
        this.$Message.success('删除成功!')
        this.formData = initFormData()
        this.getData()
      }).catch((errorMsg: string) => this.$Message.error(errorMsg))
    },
  },
})
